import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Developers Summit 2019 Summer で弊社田中がエンジニアのキャリアについてお話させていただきました",
  "date": "2019-07-19T10:43:56.000Z",
  "slug": "entry/2019/07/19/194356",
  "tags": ["medley"],
  "hero": "./2019_07_19.png",
  "heroAlt": "devsumi 2019"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`みなさん、こんにちは。開発部エンジニア平木です。`}</p>
    <p>{`少しレポートまで間が空いてしまいましたが、去る 7/2(月)にソラシティカンファレンスセンターで開催された`}<a parentName="p" {...{
        "href": "https://event.shoeisha.jp/devsumi/20190702"
      }}>{`Developers Summit 2019 Summer`}</a>{`にメドレーが協賛させていただきました。`}</p>
    <p>{`また、弊社の開発部長である田中が`}<a parentName="p" {...{
        "href": "https://event.shoeisha.jp/devsumi/20190702/session/2089/"
      }}>{`SI × Web の総合力で切り拓く新しいエンジニアのキャリアパス`}</a>{`というタイトルのセッションでお話をさせていただきましたので、レポートを書きたいと思います。`}</p>
    <h1>{`会場の雰囲気`}</h1>
    <figure {...{
      "className": "figure-image figure-image-fotolife",
      "title": "会場入口の立て看板がステキでした"
    }}><img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190719/20190719173752.jpg",
        "alt": "20190719173752.jpg"
      }}></img><figcaption parentName="figure">{`会場入口の立て看板がステキでした`}</figcaption></figure>
    <p>{`会場ですがやはり今回も来場者が多く、セッション前には列が出来ていました。休憩時間中のブースにも人だかりといった感じで熱気を感じました。`}</p>
    <undefined><figure {...{
        "className": "figure-image figure-image-fotolife",
        "title": "次のセッション待ちで長蛇の列が"
      }}><img parentName="figure" {...{
          "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190719/20190719172816.jpg",
          "alt": "20190719172816.jpg"
        }}></img><figcaption parentName="figure">{`次のセッション待ちで長蛇の列が`}</figcaption></figure>
      <figure {...{
        "className": "figure-image figure-image-fotolife",
        "title": "ブースも人が切れませんでした"
      }}><img parentName="figure" {...{
          "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190719/20190719172807.jpg",
          "alt": "20190719172807.jpg"
        }}></img><figcaption parentName="figure">{`ブースも人が切れませんでした`}</figcaption></figure></undefined>
    <p>{`しかしながら、運営が大変にスムーズだったので大きな混乱もなく次々と快適にセッションが見られるのは、やはり今までの経験によるものだろうなーと感じました。`}</p>
    <h1>{`印象に残ったセッション`}</h1>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190719/20190719174247.jpg",
      "alt": "20190719174247.jpg"
    }}></img>
    <p>{`色々とセッションを見ましたが、個人的にはやはり、`}<a parentName="p" {...{
        "href": "https://twitter.com/t_wada"
      }}>{`@t-wada`}</a>{`さんのセッションは何度見ても惹き込まれるなと今回も拝見して感じました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190719/20190719173721.jpg",
      "alt": "20190719173721.jpg"
    }}></img>
    <p>{`今回の内容を聞くと例え、全くテストを導入していないようなプロジェクトだったとしても「明日からちょっとずつやっていこう」と勇気をもらえるような内容でした。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190719/20190719173729.jpg",
      "alt": "20190719173729.jpg"
    }}></img>
    <h1>{`エンジニアのキャリアパス`}</h1>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190719/20190719173659.jpg",
      "alt": "20190719173659.jpg"
    }}></img>
    <p>{`いよいよ、弊社田中のセッションです。残念ながら、資料は非公開とさせていただいています…。申し訳ありません。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190719/20190719174601.jpg",
      "alt": "20190719174601.jpg"
    }}></img>
    <p>{`内容としては、少し前まではいわゆる SIer と Web 系という 2 つの領域で結構明確に壁を感じることもあったと思います。しかし、近年 X-Tech などの文脈では、関連省庁の法令やガイドラインなどを遵守しつつ、使いやすい UI への落とし込みやマネージドサービスを使用したインフラの構築などを設計する必要があります。これらの設計の上で、Web サービスとして高速に PDCA を回しサービスを改善していくという能力も他方で必要になります。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190719/20190719174328.jpg",
      "alt": "20190719174328.jpg"
    }}></img>
    <p>{`こういった背景により、きっちりとした要件定義や設計する能力を求められる SIer 的な要素と手を動かして早くサービスを改善するサイクルを回す能力が必要な Web 的な要素という、それぞれの得意領域をミックスしたエンジニアが求められているのではないかというお話をさせていただきました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190719/20190719173302.jpg",
      "alt": "20190719173302.jpg"
    }}></img>
    <p>{`途中田中のキャリア変遷や、弊社の CLINICS を例に取り具体的にどのような能力を求められるのか?ということをお話させていただきました。`}</p>
    <p>{`終了後のアンケートなど拝見する限り、やはり業界の括りに囚われがちなところも多いなか、キャリアパスの一つの実例として、ご好評をいただいたのは嬉しい限りでした。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`メドレーのエンジニアやデザイナーは、きっちりと法令やガイドラインを守りつつ、その概念をプロダクトの設計に落しこみ、出来るだけ早く PDCA を回し、早く確実に実装することを目指しています。Developers Summit 2019 Summer のような多種多様なキャリアの方々が来場するイベントで、このようなお話をさせていただいたのはとても有意義だったと感じています。`}</p>
    <figure {...{
      "className": "figure-image figure-image-fotolife",
      "title": "セッションが無事終了し、ほっと一息付きながらメドレーロゴと一緒に"
    }}><img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190719/20190719173131.jpg",
        "alt": "20190719173131.jpg"
      }}></img><figcaption parentName="figure">{`セッションが無事終了し、ほっと一息付きながらメドレーロゴと一緒に`}</figcaption></figure>
    <p>{`▼ メドレーってどんな会社？気になった方はこちら`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/team/"
      }}>{`https://www.medley.jp/team/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      